.footer {
    position: relative;
    height: 430px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: -60px;
        margin: 0 auto;
        width: 100%;
        height: 620px;
        background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Vague4.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    &__container {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            right: -190px;
            bottom: -60px;
            width: 200px;
            height: 210px;
            background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Bulle4.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__titles {
        color: $color-text;
        font-size: 1.375rem;
        font-weight: $font-weight-bold;
        margin: 0 0 20px;
    }

    &__infos {
        display: flex;
        justify-content: space-between;
        padding: 90px 0 0;
        color: $color-text;

        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            margin-bottom: 10px;
        }
        
        .button {
            width: 220px;
            margin: 0;

            & svg {
                margin-right: 8px;
            }
        }

        &__contact {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            width: 270px;

            .button {
                padding-left: 29px;
                padding-right: 29px;
            }

            &__rs {
                display: flex;
                margin-top: 20px;

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    margin-right: 15px;
                    padding: 0;

                    svg {
                        width: 30px;
                        height: 30px;
                        margin: 0;
                    }
                }
            }
        }

        &__phone {
            display: flex;
            align-items: center;
            font-size: 1rem;
            line-height: 1.375rem;
            font-weight: $font-weight-bold;
            margin-top: 10px;


            & svg {
                width: 26px;
                height: 26px;
            }
        }

        &__coordonnees,
        &__horaires {
            width: 300px;
        }
    }

    &__menu {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 110px 0 40px;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 580px;
        }

        li {
            font-weight: $font-weight-bold;
            position: relative;
            padding: 0 10px;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                margin-bottom: 15px;
            }

            a {
                &:hover {
                    color: $color-second;
                }
            }
        }

        &__partenaire {
            position: absolute;
            left: 75px;
            opacity: 1;
            transition: all $duration ease-in-out;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &__container {
            &:after {
                right: -160px;
                bottom: -45px;
            }
        }

        &__infos {

            &__contact {
                width: 220px;

                .button {
                    width: 220px;
                }

                &__rs {
                    .button {
                        width: 50px;
                        margin-right: 10px;
                    }
                }
            }
        }

        &__menu {
            &__partenaire {
                left: 40px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        height: 810px;
        padding-top: 55px;

        &:before {
            height: 1350px;
            top: -155px;
            background-size: cover;
        }

        &__container {
            &:after {
                right: -240px;
                bottom: 50px;
            }
        }

        &__infos {
            padding-top: 0;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &__contact {
                padding-bottom: 60px;

                &__rs {
                    justify-content: center;
                }
            }

            &__coordonnees {
                padding-bottom: 40px;
            }

            &__phone {
                justify-content: center;
            }
        }

        &__menu {
            flex-direction: column;
            align-items: center;

            &__partenaire {
                position: initial;
                width: fit-content;
            }

            &__list {
                padding-top: 40px;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        height: 850px;

        &__menu {

            ul {
                padding-top: 20px;
                width: 100%;
            }

            li {
                margin-bottom: 15px !important;
                font-size: 0.875rem;
                padding: 0 15px !important;
            }
        }
    }
}