#nav-main {
    //z-index: 10;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 610px;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-child {
            .nav-main-link {
                padding-left: 0q;
            }
        }

        &:last-child {
           .nav-main-link {
                padding-right: 0;

                &:before {
                    right: 0;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 130px;
            padding: 0 15px;
            color: $color-text;
            font-size: 1.125rem;
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;

            &:before {
                content: "";
                position: absolute;
                bottom: 48px;
                right: 15px;
                width: 50px;
                height: 6px;
                opacity: 0;
                background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Ligne-titre.svg');
                background-size: cover;
                transition: all $duration ease-in-out;
            }
        }

        &:hover {
            .nav-main-link {
                &:before {
                    opacity: 1;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-main;
            border-radius: 0 0 30px 30px;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: 0; //-1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    padding: 15px 30px;
                    margin-bottom: 5px; // Fix space beetween inline-flex
                    border: 1px solid $color-main;
                    border-radius: 50px;
                    color: $color-white;
                    font-weight: $font-weight-medium;
                    transition: all $duration ease-in-out;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        left: 0;
                        color: $color-white;
                        transition: all $duration;
                    }

                    &:hover {
                        padding-left: 45px;
                        border-color: $color-white;

                        &::after {
                            left: 15px;
                        }
                    }
                }
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    color: $color-text;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: $color-main;
        transition: all $duration ease-in-out;
    }

    &:hover {
        &:before {
            background-color: $color-dark;
        }
    }

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: 0px;
        width: 16px;
        margin-left: 23px;
        background: $color-white;
        border-radius: 1px;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            border-radius: 1px;
            transition: all $duration ease-out;
        }

        &:before {
            top: 5px;
        }

        &:after {
            top: -5px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {

        .menu__nav__list {
            width: 560px;
        }

        .nav-main-item {
            .nav-main-link {
                height: 100px;
                font-size: 1rem;

                &:before {
                    bottom: 32px;
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        display: flex;
        align-items: center;
        margin-left: 180px;

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 100px;
            left: 0;
            right: 0;
            width: 640px;
            margin: auto;
            background: $color-main;
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-main;

                    &:before {
                        content: none;
                    }
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;
                    z-index: 0;
                    border-radius: 0;

                    .nav-drop {
                        column-count: 1;
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100px;
            height: 40px;
            margin: 0;
            padding: 0;
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

    #nav-main {
        margin: 0;
        height: 40px;

        .menu__nav__list {
            width: 320px;
            top: 168px;
        }
    }
}
