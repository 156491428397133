.front-page {

    .home {

        &__section {
            position: relative;
            margin-top: 100px;
            margin-bottom: 100px;

            &__container {
                display: flex;
                justify-content: space-between;
            }

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                right: 0;
                top: -165px;
                margin: 0 auto;
                width: 100%;
                height: 490px;
                background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Vague3.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

#uci_toolbar-quick button {
    height: unset;
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 65px;
        margin: 0 auto;
        width: 100%;
        height: 610px;
        background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Vague1.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    > .container {
        position: relative;
    }

    .swiper-wrapper {
        border-radius: 0px 0px 20px 20px;
        overflow: hidden;
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
    }

    .slideshow {
        height: 440px;
        width: 100%;
        

        .swiper-slide {
            border-radius: 0 0 20px 20px;
            overflow: hidden;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 30px;
            bottom: 20px;
            width: 240px;
            height: auto;
            padding: 0;
            color: $color-white;
        }

        &__title {
            position: relative;
            z-index: 0;
            margin-bottom: 0;
            color: $color-text;
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: $font-weight-semibold;
            font-family: $font-family;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                right: -50px;
                top: -75px;
                width: 355px;
                height: 390px;
                background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Bulle1.svg');
                background-repeat: no-repeat;
                background-position: right;
                background-size: cover;
            }
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            position: absolute;
            right: 30px;
            left: unset;
            bottom: 20px;
            z-index: 2;
            width: fit-content;
            height: 15px;
            font-size: 0;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4));
    
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-white;
                border-radius: 50px;
                opacity: 1;
    
                &:hover {
                    background-color: $color-third;
                }
    
                &-active {
                    width: 60px;
                    background-color: $color-third;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    
    }
}


//======================================================================================================
// Access
//======================================================================================================
.access {

    margin-top: 50px;

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 220px;
        padding: 40px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        background-color: $color-white;
        border-radius: 20px;
    }

    &__item, &__link {
        width: 140px;
        height: 140px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 140px;
        height: 140px;

        & svg {
            transition: all $duration ease-in-out;
            
            & path[fill="#086AB8"] {
                transition: all $duration ease-in-out;
            }
        }

        &:hover {

            & svg {
                & path[fill="#086AB8"] {
                    fill: $color-dark;
                }
            }

            .access__info {
                color: $color-text;
            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        font-size: .875rem;
        line-height: 1.188rem;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        color: $color-main;
        transition: all $duration ease-in-out;
    }
}


//======================================================================================================
// Social/Actu
//======================================================================================================
.social {
    position: relative;
    //height: 680px;
    margin-top: 60px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 25px;
        margin: 0 auto;
        width: 100%;
        height: 735px;
        background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Vague2.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    &__heading {
        display: flex;
        justify-content: center;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 250px;
        height: 100px;
        margin: 0;
        padding: 0;
        font-size: 3.125rem;
        line-height: 3.438rem;
        font-family: $font-family--heading;
        color: $color-text;

        &__span {
            color: $color-medium;
            font-size: 2.5rem;
            line-height: 1.5rem;
            font-family: $font-family--subheading;
        }

        & svg {
            width: 122px;
            height: 14px;
            fill: $color-main;
            opacity: .8;
        }
    }

    &__button__container {
        display: flex;
        justify-content: flex-end;

        .button {
            width: 370px;
            min-height: 50px;
            margin: 0;
            border-color: $color-white;
            background-color: $color-white;
            color: $color-main;
            transition: all $duration ease-in-out;

            &:hover, &:focus {
                background-color: $color-dark;
                border-color: $color-dark;
                color: $color-white;
            }
        }
    }

    &__container {
        position: relative;
        height: 450px;
        margin-top: 40px;
        margin-bottom: 40px;

        &:before {
            content: "";
            position: absolute;
            top: -120px;
            right: -115px;
            width: 310px;
            height: 330px;
            background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Bulle2.svg');
        }
    }

    &__container {

        #ff-stream-1 {
            height: 450px!important;
            min-height: unset!important;
            padding: 0!important;
            background: transparent!important;

            .ff-header {
                display: none!important;
            }

            .ff-stream-wrapper {
                width: 1230px!important;
                margin: 0!important;
                margin-left: -30px!important;
                padding: 0!important;
            }

            .ff-item {
                border-radius: 0 0 10px 10px!important;
                overflow: hidden!important;
                filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08))!important;

                .ff-content {
                    max-height: 120px!important;
                    overflow: hidden!important;
                    margin: 15px 30px 15px!important;
                    height: 120px!important;
                }

                .ff-item-meta {
                    margin: 0 15px!important;
                }
            }

            .ff-item-cont {
                height: 410px!important;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .ff-img-holder {
                    min-height: 220px!important;
                    height: 220px!important;
                    width: 100%!important;

                    img {
                        min-height: 220px!important;
                        height: 220px!important;
                        object-fit: cover!important;

                    }
                }

                a {
                    color: $color-text!important;
                }
            }

            .ff-loadmore-wrapper {
                display: none!important;
            }
            
            .ff-item-bar::before {
                border-color: $color-main!important;
            }

            .ff-item-bar a:hover i::after {
                content: none!important;
            }

            .ff-item-bar a, .ff-item-bar a i {
                color: $color-main!important;
            }

            .ff-item-bar a:hover, .ff-item-bar a:hover i {
                color: $color-text!important;
            }

            .ff-item-bar .ff-share-wrapper .ff-icon-share {
                color: $color-main!important;
                background-color: transparent !important;

                &:hover {
                    color: $color-text!important;
                    background-color: transparent !important;
                }
            }

            .ff-upic-round .picture-item__inner {
                border-radius: 0!important;
            }

            .ff-upic-round .ff-img-holder:first-child, .ff-upic-round .ff-img-holder:first-child img {
                border-radius: 0!important;
            }
        }
    }
}


//======================================================================================================
// Agenda
//======================================================================================================
.events {

    &__section {
        margin-top: 80px;
    }

    &__heading {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 200px;
        height: 100px;
        margin: 0;
        padding: 0;
        font-size: 3.125rem;
        line-height: 3.438rem;
        font-family: $font-family--heading;
        color: $color-text;

        &__span {
            color: $color-medium;
            font-size: 2.5rem;
            line-height: 1.5rem;
            font-family: $font-family--subheading;
        }

        & svg {
            width: 122px;
            height: 14px;
            fill: $color-main;
            opacity: .8;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        width: 270px;
        height: 460px;
        margin-bottom: 40px;

        &:hover {
            .events {
                &__content {
                    & img {
                        transform: scale(1.1);
                    }
                }
                &__infos {
                    &__title {
                        color: $color-main;
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        height: 350px;
        overflow: hidden;
        z-index: 0;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }

        &:before {
            content: "";
            position: absolute;
            width: 270px;
            height: 350px;
            left: 0;
            top: 0;
            z-index: 1;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.36) 23.96%, rgba(0, 0, 0, 0.08) 36.46%);
        }

        & img {
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    &__infos {
        height: 190px;
        width: 240px;
        margin: -80px auto 0;

        &__date {
            display: flex;
            height: 40px;
            margin-bottom: 10px;
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));

            p:first-child {
                padding-right: 8px;
            }

            &__day {
                color: $color-white;
                font-size: 2.125rem;
                line-height: 2.5rem;
                font-weight: $font-weight-bold;
            }

            &__month {
                color: $color-white;
                font-size: 1rem;
                line-height: 1.375rem;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;
            height: 140px;
            padding: 25px;
            font-size: 1.25rem;
            line-height: 1.563rem;
            text-align: center;
            color: $color-text;
            font-weight: $font-weight-semibold;
            border: $border-width $border-style $color-main;
            background-color: $color-white;
            border-radius: 20px;
            transition: all $duration ease-in-out;
        }
    }

    &__buttons {

        &__container {
            display: flex;
            justify-content: flex-end;
        }

        .button {
            width: 370px;
            min-height: 50px;
            margin: 0;
            color: $color-white;
            transition: all $duration ease-in-out;

            &:first-child {
                margin-right: 30px;
                background-color: $color-dark;
                border-color: $color-dark;

                &:hover {
                    background-color: $color-main;
                    border-color: $color-main;

                }
            }

            &:hover {
                background-color: $color-dark;
                border-color: $color-dark;
                color: $color-white;
            }
        }
    }
}


//======================================================================================================
// Annuaire
//======================================================================================================
.annuaire {
    position: relative;
    width: 470px;
    height:390px;
    padding: 30px 50px 40px;
    margin-left: 100px;
    border-radius: 20px;
    background-color: $color-white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: -60px;
        bottom: -45px;
        width: 130px;
        height: 135px;
        background: url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Formes-BG/Bulle3.svg');
        background-size: contain;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-text;
        margin: 0;
        padding: 0;
        margin-bottom: 35px;
        font-size: 2.125rem;

        & svg {
            width: 70px;
            height: 8px;
            fill: $color-main;
            opacity: .8;
        }
    }

    &__content {

        label {
            text-align: center;
            margin: 0 0 10px 0;
            font-weight: $font-weight-bold;
        }
    }

    &__dropdown {
        appearance: none;
        text-indent: 1px;
        text-overflow: '';
        background: $color-white url('/wp-content/themes/loire-sur-rhone/assets/src/images/sprite-images/Pictos/ic-arrow.svg') no-repeat 100% center;
        background-position: calc(100% - 15px) center;
        display: flex;
        align-items: center;
        width: 415px;
        height: 46px;
        color: $color-dark;
        border: 1px solid $color-main;
        border-radius: 100px;
        padding: 2px 25px;
        position: relative;
        z-index: 1;
    }

    &__btn {
        width: 100%;
        min-height: 50px;
        margin: 15px 0 38px;
    }

    &__template-btn {
        width: 100%;
        min-height: 50px;
        margin: 0;
    }
}


//======================================================================================================
// Liens utiles
//======================================================================================================
.usefull-link {
    width: 470px;
    height:390px;
    padding: 30px 80px 40px;
    margin-right: 100px;
    border-radius: 20px;
    background-color: $color-white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-text;
        margin: 0 0 25px 0;
        padding: 0;
        font-size: 2.125rem;

        & svg {
            width: 70px;
            height: 8px;
            fill: $color-main;
            opacity: .8;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 30px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 140px;
        height: 108px;

        &:nth-child(1), &:nth-child(2) {
            margin-bottom: 30px;
        }

        &:hover {
            .usefull-link__info {
                margin-bottom: 5px;
            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        height: 38px;
        text-align: center;
        font-size: .875rem;
        line-height: 1.25rem;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        transition: all $duration ease-in-out;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        .home {
            &__section {
                margin-top: 80px;
            }
        }
    }

    // ALU
    .home-slideshow {

        .slideshow {
            height: 350px;

            &__title {
                font-size: 1rem;
            }
        }

        &:before {
            top: -30px;
        }

        .swiper-container {
            max-width: 960px;
            padding: 0 10px;
        }
    }

    // Access
    .access {

        &__list {
            padding: 40px 15px;
        }
    }


    // Social
    .social {
        //height: 630px;

        &:before {
            top: 5px;
        }

        &__container {
            height: 400px;

            &:before {
                right: -200px;
                top: -110px;
            }
        }

        &__button {
            &__container {
                .button {
                    width: 300px;
                }
            }
        }
        
        &__container {

            #ff-stream-1 {
                height: 400px!important;
                min-height: unset!important;
                padding: 0!important;
                background: transparent!important;
    
                .ff-stream-wrapper {
                    width: 980px!important;
                    margin-left: -20px!important;
                }
    
                .ff-item {
                    height: 400px!important;
                    
                    .ff-content {
                        max-height: 120px!important;
                        overflow: hidden!important;
                        margin: 15px!important;
                    }
                }

                .ff-item-cont {
                    height: 360px!important;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
    
                    .ff-img-holder {
                        min-height: 175px!important;
                        height: 175px!important;
                        width: 100%!important;
    
                        img {
                            min-height: 175px!important;
                            height: 175px!important;
                        }
                    }
                }
            }
        }
    }

    // Events
    .events {

        &__link {
            width: 220px;
            height: 375px;
            margin-bottom: 40px;
        }

        &__content {
            height: 285px;

            &:before {
                width: 220px;
                height: 285px;
            }
        }

        &__infos {
            height: 170px;
            width: 190px;
            margin-top: -80px;

            &__date {
                &__day {
                    font-size: 1.625rem;
                }
            }

            &__title {
                height: 120px;
                font-size: 1.25rem;
                line-height: 1.375rem;
            }
        }

        &__buttons {
            .button {
                width: 300px;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    // Annuaire
    .annuaire {
        width: 460px;
        height: 375px;
        padding: 25px 55px 40px;
        margin-left: 0;

        &__btn {
            margin-bottom: 30px;
        }

        &:before {
            left: -75px;
            bottom: -70px;
        }
    }

    // Liens utiles
    .usefull-link {
        width: 460px;
        height: 375px;
        padding: 25px 75px 40px;
        margin-right: 0;

        &__list {
            column-gap: 20px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        .home {

            &__section {
                margin-top: 100px;
                margin-bottom: 80px;

                &__container {
                    flex-direction: column;
                    align-items: center;
                }

                &:before {
                    top: 135px;
                }
            }
        }
    }

    // ALU
    .home-slideshow {

        &:before {
            top: -60px;
        }

        .slideshow {
            height: 360px;
            border-radius: 0px 0px 20px 20px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            .slideshow__container {
                .container--content {
                    position: relative;
                    height: 100%;
                }
            }

            &__content {
                left: 20px;
                bottom: 20px;
            }

            &__title {
                &:before {
                    top: -120px;
                    right: -40px;
                    background-size: contain;
                }
            }
        }

        .swiper-container {
            max-width: unset;
            padding: 0;
        }

        .slideshow__pagination {
            max-width: 640px;
            padding: 0 10px;
            margin: 0 auto;
            right: 0;
            left: 0;
            bottom: 20px;
            width: 100%;
            text-align: right;
        }
    }

    // Access
    .access {

        &__list {
            height: 455px;
            flex-wrap: wrap;
            padding: 40px 80px;
        }

        &__item {
            margin: 20px 0;
        }
    }

    // Social
    .social {

        &:before {
            top: 230px;
        }

        &__container {
            height: 1020px;

            &:before {
                right: -280px;
                top: 120px;
            }
        }

        &__button__container {
            justify-content: center;

            .button {
                background-color: $color-main;
                color: $color-white;
            }
        }

        &__container {

            #ff-stream-1 {
                height: 820px!important;
                min-height: unset!important;
                padding: 0!important;
                background: transparent!important;
    
                .ff-stream-wrapper {
                    width: 660px!important;
                }
    
                .ff-item {
                    height: 500px!important;

                    .ff-content {
                        max-height: 145px!important;
                        overflow: hidden!important;
                        margin: 15px!important;
                        height: 145px!important;
                    }
                }

                .ff-item-cont {
                    height: 458px!important;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
    
                    .ff-img-holder {
                        min-height: 245px!important;
                        height: 245px!important;
                        width: 100%!important;
    
                        img {
                            min-height: 245px!important;
                            height: 245px!important;
                        }
                    }
                }
            }
        }
    }

    // Events
    .events {

        &__container {
            height: auto;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__content {
            width: 300px;
            height: 390px;

            &:before {
                width: 300px;
                height: 390px;
            }
        }

        &__infos {
            width: 260px;
            margin-top: -100px;

            &__title {
                height: 120px;
            }
        }

        &__link {
            width: 300px;
            height: 460px;
            margin-bottom: 30px;

            &:nth-child(1), &:nth-child(3) {
                margin-right: 20px;
            }
        }
    }

    // Annuaire
    .annuaire {
        margin-bottom: 30px;

        &:before {
            left: -90px;
            bottom: -450px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    // ALU
    .home-slideshow {

        &:before {
            top: -125px;
        }

        .slideshow {
            height: 280px;
            border-radius: 0px 0px 20px 20px;
            overflow: unset;
            
            &__content {
                width: 210px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
            }

            &__title {
                &:before {
                    top: -90px;
                    right: -70px;
                }
            }
        }

        .slideshow__pagination {
            position: initial;
            max-width: 320px;
            padding: 0 10px;
            margin: 15px auto 0;
            text-align: center;
            filter: unset;

            .swiper-pagination-bullet {
                position: relative;

                /*&:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 15px;
                    width: 100%;
                    border-radius: 50px;
                    background-color: transparent;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09);
                }*/
            }
        }
    }

    // Access
    .access {
        margin-top: 70px;

        &__list {
            height: 625px;
            width: 380px;
            margin-left: -40px;
            padding: 50px 30px;
        }

        &__item {
            margin: 20px 10px;
        }
    }

    // Social
    .social {

        &:before {
            top: 570px;
        }

        &__container {
            height: 2060px;

            &:before {
                right: -200px;
                top: 450px;
            }
        }

        &__container {

            #ff-stream-1 {
                height: 1660px!important;
                min-height: unset!important;
                padding: 0!important;
                background: transparent!important;
    
                .ff-stream-wrapper {
                    width: 340px!important;
                }
            }
        }
    }

    // Events
    .events {

        &__container {
            height: auto;
        }

        &__link {
            margin-right: 0!important;
        }

        &__buttons__container {
            display: block;

            .button {
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    // Annuaire
    .annuaire {
        width: 380px;
        padding: 25px 40px 40px;
    }

    // Liens utiles
    .usefull-link {
        width: 380px;
        padding: 25px 40px 40px;
    }
}