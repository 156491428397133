header {
    position: relative;
    z-index: 11;
}

.header-top {
    background-color: $color-white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    transition: all $duration ease-in-out;
    
    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 130px;

        .site-identity {
            display: flex;
            align-items: center;
        }

        .identity {
            height: 74px;
            
            &__title {
                font-size: 0;
                line-height: 0;
            }
        }

    }
}

.header--fixed .header-top {
    position: fixed;
    width: 100%;
    background-color: $color-white;

    .container--identity {
        height: 80px;

        .identity {
            height: 58px;
        }

        .identity__title {
            
            & svg {
                width: 231px;
                height: 58px;
            }
        }
    }

    #nav-main {
        margin-left: 70px;

        .nav-main-item {
            .nav-main-link {
                height: 80px;

                &:before {
                    bottom: 24px;
                }
            }
        }
    }

    .tools {
        .container--tools {
            height: 80px;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    .container--tools {
        display: flex;
        justify-content: flex-end;
        height: 130px;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-white; 
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border: 0;
        color: $color-white;
        
        svg {
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__accessibility {
        height: 40px;
        width: 40px;
        padding: 0;
        margin: 0 15px 0 0;
        background-color: $color-dark;

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover, &:focus {
            background-color: $color-second;
        }
    }

    &__search {
        height: 40px;
        width: fit-content;
        margin: 0;
        padding: 0 17px !important;
        background-color: $color-second;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            margin-right: 8px;
            width: 30px;
            height: 30px;
        }

        &:hover, &:focus {
            background-color: $color-dark;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header .header-top {

        .container--identity {
            height: 100px;

            .identity {
                height: 58px;
            }

            .identity__title {

                & svg {
                    width: 231px;
                    height: 58px;
                }
            }
        }
    }

    .header--fixed .header-top {
        .container--identity {
            height: 80px;
        }

        #nav-main {
            margin-left: 0;
        }
    }

    .tools {

        .container--tools {
            height: 100px;
        }

        &__accessibility {
            margin-right: 10px;
        }

        &__search {
            padding: 0!important;
            width: 40px;
            font-size: 0;
            line-height: 0;

            & svg {
                margin-right: 0;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .header-top {

        .container--identity {
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-height: 90px;
            height: auto;
        }
    }

    .tools {
        position: relative;
        top: 0;

        .container--tools {
            display: flex;
            justify-content: flex-end;
            position: relative;

            .tools__content {
                margin: 0;
            }
        }
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header {
        .header-top {
            .container--identity {
                height: 168px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    .tools {
        .container--tools {
            height: 40px;
        }

        .tool{
            margin: 0;
        }

        &__accessibility {
            margin: 0 10px!important;
        }
    }
}