.breadcrumb {

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
        justify-content: center;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: $color-gray;
        padding: 0 15px;

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &::after {
            content: '\f345';
            font: normal 20px/1 dashicons;
            position: absolute;
            right: -10px;
            color: $color-gray;
            transition: all $duration;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
            box-shadow: 0px -1px 0 rgb(32 32 34 / 10%);
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
